$asset-path: "undefined";
@import 'variables';
@import 'typography';
@import 'components';
@import 'function';
@import 'mixins';

:root {
  // basic colors
  --primary-color: #4947be;
  --black: #000;
  --white: #fff;
  --gray: #8e8e8e;

  // black
  --black1: #333333;
  --black-opacity: #0000002e;

  // secondary
  --secondary: #4d4d4d;
  --secondary-disabled: #e5e5e5;

  // gray-dark
  --gray-dark: #666666;
  --gray-dark-disabled: #999999;
  --gray-dark1: #9b9fa8;
  --gray-dark2: #bbbbbb;
  --gray-dark3: #777777;

  // light-gray
  --light-gray1: #f5f5f5;
  --light-gray1-disabled: #dddddd;
  --light-gray2: #f9f9f9;
  --light-gray3: #fafafa;
  --light-gray4: #cccccc;
  --light-gray5: #f2f3f5;
  --light-gray6: #f2f2f2;
  --light-gray7: #aaaaaa;
  --light-gray8: #888888;
  --light-gray9: #b6a29a;

  // gold
  --gold1: #989445;
  --gold2: #989442;
  --gold3: #ada968;
  --gold5: #d9d9d3;
  --gold6: #807f6c;
  --gold7: #72715c;
  --gold8: #666654;

  // red
  --error: #f00;
  --datepicker-sunday: #de3636;
}

.layout,
.ReactModalPortal {
  font-family: 'gotham-light', 'playfair';
  .ReactModal__Overlay {
    z-index: 9999990;
  }

  * {
    margin: 0;
    padding: 0;
    list-style-type: none;
    white-space: pre-line;
  }
}

body {
  color: #0c0c0c;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img,
svg {
  vertical-align: middle;
}

a {
  text-decoration-line: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

img[src$='.gif'],
img[src$='.png'] {
  image-rendering: crisp-edges;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.hide {
  display: none !important;
}

.hidden-lazy-load {
  height: 20px;
  width: 100%;
}

.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

.text-uppercase {
  text-transform: uppercase;
}
