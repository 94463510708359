$asset-path: "undefined";
@import '@/styles/variables/palette';
@import '@/styles/mixins';

.spinner-component {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999999999;
  width: 100vw;
  height: 100vh;

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $black-opacity;
    min-height: 100vh;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include desktop {
        max-width: 120px;
        max-height: 120px;
      }
      @include mobile {
        max-width: 80px;
        max-height: 70px;
      }
    }
  }
}
