$asset-path: "undefined";
body,
#root,
#root > div {
  min-height: 100%;
}

body {
  margin: 0;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

#root > div {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  width: 100%;
}

div:focus {
  outline: 0;
}

button:focus {
  outline: 0;
}
