@mixin mobile {
  @media only screen and (width <= 1399px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (width >= 1400px) {
    @content;
  }
}
