// basic colors
$black: var(--black);
$white: var(--white);
$primary: var(--primary-color);
$gray: var(--gray);

// black
$black1: var(--black1);
$black-opacity: var(--black-opacity);

// secondary
$secondary: var(--secondary);
$secondary-disabled: var(--secondary-disabled);

// gray-dark
$gray-dark: var(--gray-dark);
$gray-dark-disabled: var(--gray-dark-disabled);
$gray-dark1: var(--gray-dark1);
$gray-dark2: var(--gray-dark2);
$gray-dark3: var(--gray-dark3);

// light-gray
$light-gray1: var(--light-gray1);
$light-gray1-disabled: var(--light-gray1-disabled);
$light-gray2: var(--light-gray2);
$light-gray3: var(--light-gray3);
$light-gray4: var(--light-gray4);
$light-gray5: var(--light-gray5);
$light-gray6: var(--light-gray6);
$light-gray7: var(--light-gray7);
$light-gray8: var(--light-gray8);
$light-gray9: var(--light-gray9);

// gold
$gold1: var(--gold1);
$gold2: var(--gold2);
$gold3: var(--gold3);
$gold5: var(--gold5);
$gold6: var(--gold6);
$gold7: var(--gold7);
$gold8: var(--gold8);

// error
$error: var(--error);
$datepicker-sunday: var(--datepicker-sunday);
