@import '@/styles/variables/palette';

input {
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $gray;
  }

  &[type='checkbox'],
  &[type='radio'] {
    height: 18px;
    transform: scale(1.3);
    margin: 0 13px 0 4px;
    accent-color: $primary;
  }
}
