// 공통 변수 정의
$font-sizes: (
  h1: 60px,
  h2: 40px,
  h3: 36px,
  h4: 30px,
  h5: 24px,
  sub1: 20px,
  sub2: 18px,
  p1: 16px,
  p2: 14px,
  p3: 12px,
);

$line-heights: (
  h1: 72px,
  h2: 48px,
  h3: 44px,
  h4: 40px,
  h5: 34px,
  sub1: 30px,
  sub2: 26px,
  p1: 24px,
  p2: 20px,
  p3: 16px,
);

// Playfair Font
$playfair-font-family: 'Playfair Display';
$playfair-font-weight: 400;

@mixin playfair-font($font-size, $line-height, $size) {
  font-family: $playfair-font-family;
  font-weight: $playfair-font-weight;
  font-size: $font-size;
  line-height: $line-height;

  @if $size == h2 or $size == h5 or $size == h4 {
    text-transform: uppercase;
  }
}

@each $size, $font-size in $font-sizes {
  .pf-#{$size} {
    &-400 {
      @include playfair-font($font-size, map-get($line-heights, $size), $size);
    }
  }
}

// Gotham Font
$gotham-fonts: (
  200: 'gotham-light',
  400: 'gotham-book',
  500: 'gotham-medium',
);

@mixin gotham-font($font-size, $line-height, $weight) {
  font-family: map-get($gotham-fonts, $weight);
  font-weight: $weight;
  font-size: $font-size;
  line-height: $line-height;
}

@each $size, $font-size in $font-sizes {
  .gt-#{$size} {
    @each $weight in 200, 400, 500 {
      &-#{$weight} {
        @include gotham-font(
          $font-size,
          map-get($line-heights, $size),
          $weight
        );
      }
    }
  }
}
